import React from "react"
import LayoutA4 from "../../components/layout-a4"
import  LayoutA4Main from '../../components/layout-a4-main'
import photosStyles from './a4_photos.module.css'
import ImagesSwiperA2 from '../../components/images-swiper-a2'

export default class A4Photos extends React.Component {
  componentWillMount() {
    this.setState({
      showSwiper:false
    })
  }

  showSwiperImage=(index)=>{
    this.setState({
      imageIndex:index,
      showSwiper:true
    })
  }
  closeSwiper=()=>{
    this.setState({
      showSwiper:false
    })
  }
  render() {
    const photos=['https://cdni.cugefit.com/szmls20170312/s/ae5a4729-a6c6-478b-b2ae-409bedfcc70c_900x600.jpeg',
      'https://cdni.cugefit.com/szmls20170312/s/5aa17d5b-dcf4-4661-9926-e88239a4be5c_900x600.jpeg',
      'https://cdni.cugefit.com/szmls20170312/s/ed8ef306-5795-4ec4-ad65-7c405ea8c18b_900x600.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/0f901f59-365a-48cf-9bdb-745aae9784cc_900x1190.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/1a854aa1-e2f5-42f6-b27c-e94a4890d086_900x637.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/21d92fcc-5ba6-450a-8c7f-43042769f7cf_900x474.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/591f320c-cd80-4882-b8c8-22e1b9eca053_900x600.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/5d76a590-9d11-4e59-aebb-9eaaf89cf752_900x642.jpeg',
      'https://cdni.cugefit.com/szmls20170312/p/75b7f65c-e685-493a-a79d-e4796028c6dc_900x651.jpeg']
    return(
      <LayoutA4  >
        <LayoutA4Main>
        <div className={photosStyles.showPhotos}>
          <div className={photosStyles.photosList}>
            {photos.map((photo,index)=><div key={index} className={photosStyles.photoItem} onClick={()=>this.showSwiperImage(index)}>
              <img src={photo} alt="比赛照片"/>
            </div>)}
          </div>
        </div>
        {this.state.showSwiper&&<div className={photosStyles.swiperPhotos}>
          <div className={photosStyles.deleteIcon}>
            <img src={require('../../images/a2/deleted.png')} onClick={this.closeSwiper}/>
          </div>
          <ImagesSwiperA2 imageUrls={photos} imageIndex={this.state.imageIndex}/>
        </div>}
        </LayoutA4Main>
      </LayoutA4>
    )
  }
}