import React from 'react'
import './images-swiper-a2.css'
import Swiper from 'swiper'
import PropTypes from 'prop-types'



export default class ImagesSwiperA2 extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired,
    imageIndex:PropTypes.number.isRequired
  }
  componentDidMount() {
    var swiper = new Swiper('.swiper-container', {
      zoom: true,
      initialSlide:this.props.imageIndex,
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
      // console.log("this.swiper:",this.swiper)
      // this.swiper.slideTo(this.props.imageIndex)

  }

  render(){

    const images=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide swiper-a2"  >
      <div className="swiper-zoom-container">
        <img src={image}/>
      </div>
    </div>)
    return (
      <div className="swiper-window">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {images}
          </div>

          <div className="swiper-pagination swiper-pagination-white"></div>

          <div className="swiper-button-prev" style={{color:'white',marginLeft:'8%'}}></div>
          <div className="swiper-button-next" style={{color:'white',marginRight:'8%'}}></div>
        </div>
      </div>
    )
  }


}